import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { accessibilityTags, locationTypes } from '../../state/filter';
import { favoritesLocationIds, locations } from '../../state/location';
import { removeSavedLocation, saveLocation } from '../../util/savedLocations';
import PAGE_SIZE from '../../config/pageSize';
import LocationListItem from './LocationListItem';
import LocationItemListLoader from './LocationListItemLoader';
import toast from 'react-hot-toast';

const LocationList = ({ allLocations, isLoading }) => {
  const allLocationTypes = useRecoilValue(locationTypes);
  const allAccessibilityTags = useRecoilValue(accessibilityTags);
  const allTags = allAccessibilityTags.flatMap((cat) => cat.tags);

  const [favorites, setFavorites] = useRecoilState(favoritesLocationIds);
  const setLocations = useSetRecoilState(locations);

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isFavorites = query.get('favorites') === 'true';
  const fetchCount = query.get('ids')?.split(',').length || PAGE_SIZE;

  const getTags = (ids) => {
    return allTags.filter((tag) => ids.includes(tag.id));
  };

  const handleFavorite = (locationId) => {
    const isSaved = favorites.includes(locationId);

    const icon = isSaved ? '✖️' : '🖤️';
    const message = isSaved
      ? 'Borttagen från favoriter'
      : 'Tillagd i favoriter';

    if (isSaved) {
      setFavorites(favorites.filter((id) => id !== locationId));
      removeSavedLocation(locationId);
    } else {
      setFavorites([...favorites, locationId]);
      saveLocation(locationId);
    }

    toast(message, {
      icon: icon,
      position: 'top-center',
      duration: 1000,
      style: {
        color: '#1E1E1E',
        background: '#FEFEFE',
        border: '1px solid #1E1E1E',
        padding: '16px',
      },
    });

    if (isSaved && isFavorites) {
      const newLocations = allLocations.filter(
        (location) => location.id !== locationId
      );
      setLocations(newLocations);

      if (newLocations.length === 0) {
        navigate('/');
      }
    }
  };

  return (
    <div className="relative location-list-grid px-sm md:px-md lg:px-lg pt-8">
      {allLocations?.map((location) => {
        const locationType = allLocationTypes?.find(
          (e) => e.id === location.location_type
        );
        return (
          <LocationListItem
            key={location.id}
            id={location.id}
            name={location.name}
            address={location.address}
            municipality={location.municipality}
            locationTypeLabelSingular={locationType?.label_singular}
            locationType={locationType?.label_singular}
            locationTypeName={locationType?.name}
            image={location.image}
            accessibilityTags={getTags(location.accessibility_tags)}
            isFavorite={favorites.includes(location.id)}
            setFavorite={() => handleFavorite(location.id)}
          />
        );
      })}
      {isLoading &&
        Array(fetchCount)
          .fill(null)
          .map((_, idx) => <LocationItemListLoader key={idx} />)}
    </div>
  );
};

LocationList.propTypes = {
  allLocations: propTypes.array,
  isLoading: propTypes.bool,
};

export default LocationList;
