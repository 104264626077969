import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import InputField from '../../../InputField';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  accessibilityTags,
  selectedAccessibilityTypes,
} from '../../../../../state/filter';
import SubmitButton from '../../../buttons/SubmitButton';
import getTagIcon from '../../../../../util/getTagIcon';
import tagType from '../../../../../config/tagType';
import FilterTypePickerButton from './FilterTypePickerButton';
import ClearFilterButton from '../../../buttons/ClearFilterButton';
import generateFilterText from '../../../../../util/generateFilterText';

const AccessibilityPicker = ({ availableOptions, onSubmit }) => {
  const [search, setSearch] = useState('');
  const allAccessibilityTypes = useRecoilValue(accessibilityTags);
  const [selected, setSelected] = useRecoilState(selectedAccessibilityTypes);
  const resetSelected = useResetRecoilState(selectedAccessibilityTypes);
  const [hoverItem, setHoverItem] = useState(null);

  const handleReset = () => {
    resetSelected();
    onSubmit(null);
  };

  useEffect(() => {
    setSelected((prevSelected) =>
      prevSelected.filter((id) =>
        availableOptions.some((option) => option.id === id)
      )
    );
  }, [availableOptions]);

  const filteredAccessibilityTypes = search
    ? allAccessibilityTypes
        .map((category) => {
          const filteredTags = category.tags.filter((tag) =>
            tag.label.toLowerCase().startsWith(search.toLowerCase())
          );

          return {
            ...category,
            tags: filteredTags,
          };
        })
        .filter((category) => category.tags.length > 0)
        .sort((a, b) => {
          const aInData =
            availableOptions?.find((o) => o.id === a.id)?.count || -1;
          const bInData =
            availableOptions?.find((o) => o.id === b.id)?.count || -1;
          return bInData - aInData;
        })
    : allAccessibilityTypes;

  const onSelect = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  return (
    <div className="flex flex-col space-y-2 h-full select-none">
      <div className="flex justify-between bg-accent rounded-full p-2 space-x-2">
        <InputField
          placeholder="Sök på tillgänglighet"
          text={search}
          setText={setSearch}
        />
        <SubmitButton
          label={
            selected.length > 0 ? `Spara val (${selected.length})` : 'Nästa'
          }
          className="w-2/5"
          ariaLabel={`Spara dina val (${generateFilterText(
            allAccessibilityTypes.flatMap((categories) => categories.tags),
            selected,
            'Spara'
          )})`}
          onClick={() => onSubmit(selected)}
        />
      </div>
      <div className="overflow-y-scroll size-full space-y-2">
        {filteredAccessibilityTypes.map((categories, idx) => (
          <div key={idx} className="border-b border-border">
            <label className="text-text2">{categories.label}:</label>
            <div className="size-full dropdown-grid">
              {categories.tags.map((type, idx) => {
                const option = availableOptions?.find((o) => o.id === type.id);
                return (
                  <FilterTypePickerButton
                    key={idx}
                    label={`${type.label} ${option ? `(${option.count})` : ''}`}
                    isDisabled={!option}
                    id={type.id}
                    onClick={() => onSelect(type.id)}
                    isActive={selected.includes(type.id)}
                    icon={getTagIcon(type.name, tagType.ACCESSIBILITY)}
                    onMouseEnter={() => setHoverItem(type.id)}
                    onMouseLeave={() => setHoverItem(null)}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <div className="py-2 flex space-x-4 items-end">
        {selected.length > 0 && (
          <ClearFilterButton
            onClick={handleReset}
            buttonStyle="w-fit h-10"
            ariaLabel="Rensa filter för tillgänglighet"
          />
        )}
        <span>
          {allAccessibilityTypes
            .flatMap((cat) => cat.tags)
            .filter((tag) => tag.id === hoverItem)
            ?.map((tag) => {
              return (
                <div className="flex space-x-4 animate-fadeIn items-center">
                  <div>{getTagIcon(tag.name, tagType.ACCESSIBILITY)}</div>
                  <span className="text-text1 font-medium">
                    {tag.description}
                  </span>
                </div>
              );
            })}
        </span>
      </div>
    </div>
  );
};

AccessibilityPicker.propTypes = {
  availableOptions: propTypes.array,
  onSubmit: propTypes.func,
};

export default AccessibilityPicker;
